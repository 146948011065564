// @ts-strict-ignore
// import { Auth } from "aws-amplify"
import React, { useEffect, useState } from "react"

import { Auth, CognitoUser } from "@aws-amplify/auth"
import { navigate } from "@reach/router"
import { PageProps } from "gatsby"
import styled from "styled-components"

import Loading from "@kiwicom/orbit-components/lib/Loading"

import Layout from "components/layout"
import LoginModal from "components/login-form"
import PasswordSetModal from "components/password-set-modal"

import { useGlobalDispatch } from "utils/state-utils"
import { paramIsSetToTrue } from "utils/string-utils"

const PageLoaderWrapper = styled.div`
  margin: auto;
`

const Page = (props: PageProps) => {
  const code = props.params.code
  const username = props.params.username

  const [tryLogin, setTryLogin] = useState(true)
  const [loginFailed, setLoginFailed] = useState(false)
  const [promptForPassword, setPromptForPassword] = useState(false)
  const [cognitoUser, setCognitoUser] = useState(null as CognitoUser | null)

  const dispatch = useGlobalDispatch()

  const navigateToRedirectUrl = () => {
    const search = new URLSearchParams(props.location.search)
    const redirectUrl = search.get("redirect")
    let url = redirectUrl
    if (url && (url.includes("signup") || url.includes("login"))) {
      url = null
    }
    navigate(url ? decodeURIComponent(url) : "/account/")
  }

  useEffect(() => {
    if (tryLogin) {
      setTryLogin(false)
      initSignIn()
    }
  }, [tryLogin])

  const initSignIn = async () => {
    try {
      const user = await Auth.signIn(username)
      const search = new URLSearchParams(props.location.search)
      if (paramIsSetToTrue(search.get("prompt_for_password"))) {
        setPromptForPassword(true)
        setCognitoUser(user)
      } else {
        finishSignIn(user)
      }
    } catch (e) {
      const search = new URLSearchParams(props.location.search)
      const softFailure = search.get("soft_failure")
      if (softFailure) {
        navigateToRedirectUrl()
      } else {
        setLoginFailed(true)
      }
    }
  }

  const finishSignIn = async (user: CognitoUser, password?: string) => {
    try {
      await Auth.sendCustomChallengeAnswer(user, code, {
        password,
      })
      dispatch({ shouldRequestAccount: true })
      navigateToRedirectUrl()
    } catch (e) {
      const search = new URLSearchParams(props.location.search)
      const softFailure = search.get("soft_failure")
      if (softFailure) {
        navigateToRedirectUrl()
      } else {
        setLoginFailed(true)
      }
    }
  }

  function handlePasswordSet(password: string) {
    setPromptForPassword(false)
    finishSignIn(cognitoUser, password)
  }

  return (
    <Layout title="Login" hideContent={true}>
      {loginFailed ? (
        <LoginModal
          fullScreen
          title="Sorry, your log in failed"
          description="Try requesting a new log in link. Note that links can only be used once and expire after a short period so make sure you are clicking the latest email."
        />
      ) : promptForPassword ? (
        <PasswordSetModal fullScreen handlePasswordSet={handlePasswordSet} />
      ) : (
        <PageLoaderWrapper>
          <Loading type="pageLoader" text="Logging in" />
        </PageLoaderWrapper>
      )}
    </Layout>
  )
}

export default Page
